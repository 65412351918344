import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import {
  faHome,
  faUsers,
  faPhone,
  faPrint,
  faFileAlt,
  faFileExport,
  faSave,
  faQuestion,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const StatisticCard = ({ iconColor, label, value, trendColor }) => (
  <Card className={`p-4 bg-blue-200 rounded-lg shadow-lg text-center mb-4`}>
    <span className={`text-cyan-600 text-2xl`}>
      <FontAwesomeIcon icon={getIcon(iconColor)} />
    </span>
    <CardContent>
      <Typography variant="subtitle1" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="h5" className="mt-2">
        {value}
      </Typography>
    </CardContent>
  </Card>
);

const CustomButton = ({
  label,
  borderColor,
  hoverBorderColor,
  bgColor,
  hoverBgColor,
  textColor,
  hoverTextColor,
  icon,
  linkTo, // New prop for link destination
  onClick,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (linkTo) {
      navigate(linkTo);
    }
  };

  return (
    <Button
      variant="outlined"
      className={`font-bold p-2 rounded transition duration-300 ease-in-out shadow-md py-2 px-6 inline-flex items-center m-3 border-${borderColor} text-${textColor}`}
      sx={{
        ":hover": {
          borderColor: `border-cyan`,
          backgroundColor: `hover:bg-cyan-200`,
          color: `hover:text-${hoverTextColor}`,
        },
      }}
      startIcon={icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

const getIcon = (color) => {
  const iconMap = {
    pink: faHome,
    red: faUsers,
    blue: faPhone,
    green: faQuestion,
    yellow: faQuestion,
  };

  return iconMap[color] || null;
};

const Statistics = ({ data }) => {
  const phoneCount = data.filter((item) => item.Home_Phone !== "").length;
  const republicianPartyCount = data.filter(
    (item) => item.Party_Code === "Republican"
  ).length;
  const democratPartyCount = data.filter(
    (item) => item.Party_Code === "Democrat"
  ).length;
  const otherPartyCount = data.filter(
    (item) => item.Party_Code === "other"
  ).length;
  const maleCount = data.filter((item) => item.Gender === "Male").length;
  const femaleCount = data.filter((item) => item.Gender === "Female").length;
  const unknownCount = data.filter((item) => item.Gender === "Unknown").length;
  const uniqueAddressCount = data.reduce((uniqueAddresses, item) => {
    const isAddressDuplicate = uniqueAddresses.some((address) =>
      [
        "House_Number",
        "House_Number_Suffix",
        "Street_Name",
        "Apartment_Number",
        "Address_Line_2",
        "City",
      ].every((field) => String(address[field]) === String(item[field]))
    );

    if (!isAddressDuplicate) {
      uniqueAddresses.push({
        House_Number: item.House_Number,
        House_Number_Suffix: item.House_Number_Suffix,
        Street_Name: item.Street_Name,
        Apartment_Number: item.Apartment_Number,
        Address_Line_2: item.Address_Line_2,
        City: item.City,
      });
    }

    return uniqueAddresses;
  }, []).length;
  const ageGroupCounts = {
    "18-30": data.filter((item) => item.Age >= 18 && item.Age <= 30).length,
    "31-45": data.filter((item) => item.Age >= 31 && item.Age <= 45).length,
    "46-60": data.filter((item) => item.Age >= 46 && item.Age <= 60).length,
    "61+": data.filter((item) => item.Age >= 61).length,
  };
  const [open, setOpen] = useState(false);
  const [counts, setCounts] = useState(null);

  const handleCountButtonClick = () => {
    const allCounts = {
      "Age 18-30": ageGroupCounts["18-30"],
      "Age 31-45": ageGroupCounts["31-45"],
      "Age 46-60": ageGroupCounts["46-60"],
      "Age 60+": ageGroupCounts["61+"],
      "Party Republican": republicianPartyCount,
      "Party Democrat": democratPartyCount,
      "Party Other": otherPartyCount,
      Male: maleCount,
      Female: femaleCount,
      "Unknown Gender": unknownCount,
      "Phone Number": phoneCount,
      // "Unique Addresses": uniqueAddressCount,
    };

    setCounts(allCounts);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Typography variant="h4" className="text-2xl font-bold p-4">
        Search Result
      </Typography>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <StatisticCard
          iconColor="red"
          label="Age"
          value={`18-30:${ageGroupCounts["18-30"]}, 31-45:${ageGroupCounts["31-45"]}, 46-60:${ageGroupCounts["46-60"]}, 61+:${ageGroupCounts["61+"]}`}
          trendColor="green"
        />
        {/* <StatisticCard
          iconColor="pink"
          label="Party"
          value={`Republican :${republicianPartyCount} Democrat:${democratPartyCount}`}
          trendColor="green"
        /> */}
        <StatisticCard
          iconColor="red"
          label="People"
          value={`Male:${maleCount} Female:${femaleCount} Unknown:${unknownCount}`}
          trendColor="green"
        />
        <StatisticCard
          iconColor="blue"
          label="Phone Number"
          value={phoneCount}
          trendColor="red"
        />
      </div>

      <div className="flex items-center justify-center mt-5">
        <div className="px-3 ">
          <CustomButton
            label="Print"
            borderColor="green-500"
            hoverBorderColor="green-600"
            bgColor="white"
            hoverBgColor="green-500"
            textColor="gray-800"
            hoverTextColor="white"
            icon={faPrint}
          />
        </div>
        <div className="px-3 ">
          <CustomButton
            label="Reports"
            borderColor="red-500"
            hoverBorderColor="red-600"
            bgColor="white"
            hoverBgColor="red-500"
            textColor="gray-800"
            hoverTextColor="white"
            icon={faFileAlt}
          />
        </div>
        {/* <div className="px-3 ">
          <CustomButton
            label="Export"
            borderColor="yellow-500"
            hoverBorderColor="yellow-600"
            bgColor="white"
            hoverBgColor="yellow-500"
            textColor="gray-800"
            hoverTextColor="white"
            icon={faFileExport}
            linkTo="/export"
          />
        </div> */}
        <div className="px-3 ">
          <CustomButton
            label="Save Search Criteria"
            borderColor="green-500"
            hoverBorderColor="green-600"
            bgColor="white"
            hoverBgColor="green-500"
            textColor="gray-800"
            hoverTextColor="white"
            icon={faSave}
          />
        </div>
        <div className="">
          {/* <div className="px-3 ">
            <CustomButton
              label="Count"
              borderColor="green-500"
              hoverBorderColor="green-600"
              bgColor="white"
              hoverBgColor="green-500"
              textColor="gray-800"
              hoverTextColor="white"
              icon={faPeopleGroup}
              onClick={handleCountButtonClick}
            />
          </div> */}
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-md w-96">
                <h2
                  id="transition-modal-title"
                  className="text-2xl font-bold mb-4"
                >
                  Counts
                </h2>
                <div id="transition-modal-description">
                  {counts &&
                    Object.entries(counts).map(([label, value]) => (
                      <p key={label} className="mb-2">
                        <span className="font-semibold">{label}:</span> {value}
                      </p>
                    ))}
                </div>
                <Button
                  variant="outlined"
                  className="mt-4 w-full"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};

export default Statistics;
