import { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import Home from "./pages/Home";
import Filter from "./pages/Filter";
import Navbar from "./components/Navbar";
import Profile from "./pages/Profile";
import Export from "./pages/Export";
import Search from "./pages/Search";

function App() {
  const handleGlobalSearch = (searchTerm) => {
    console.log("Searching for:", searchTerm);
  };
  return (
    <div className="bg-gray-100 ">
      <Navbar onGlobalSearch={handleGlobalSearch} />
      <div className="p-4">
        <Routes>
          <Route path="/filter" element={<Home />} />
          <Route path="/" element={<Filter />} />
          <Route path="/export" element={<Export />} />
          <Route path="/search" element={<Search />} />
          <Route path="/profile/:id" element={<Profile />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
