import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "../components/TableComponent";

const Search = () => {
  const [aggregationData, setAggregationData] = useState([]);

  useEffect(() => {
    fetchAggregationData();
  }, []);

  const fetchAggregationData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/aggregation`);

      const rowsWithId = response.data.data.map((row, index) => ({
        ...row,
        id: index + 1,
      }));

      setAggregationData(rowsWithId);
    } catch (error) {
      console.error("Error fetching aggregation data:", error);
    }
  };

  return (
    <div className="bg-gray-100">
      <h2>Aggregation Data</h2>
      <DataTable data={aggregationData} />
    </div>
  );
};

export default Search;
