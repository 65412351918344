import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControl,
  TextField,
} from "@mui/material";

const DistrictInfo = forwardRef(
  ({ onDistrictData, selectedFilters, setSelectedFilters }, ref) => {
    const [optionData, setOptionData] = useState([]);
    const [countyData, setCountyData] = useState([]);

    useEffect(() => {
      // Fetch initial data when the component mounts
      fetchOptionData();
    }, []);

    const fetchOptionData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/data`
        );
        console.log("data", res);

        if (res.data) {
          setCountyData(res.data);
          setOptionData(res.data);
        } else {
          console.log("Data is empty or undefined.");
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleChange = async (filterName, newValue) => {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: newValue,
      }));

      if (filterName === "selectedCounty" && newValue.length > 0) {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/get_precincts`,
            {
              fieldName: "County",
              County: newValue,
            }
          );

          if (res.data) {
            setOptionData(res.data);
          } else {
            console.log("Data is empty or undefined.");
          }
        } catch (error) {
          console.log(error);
        }
      } else if (filterName === "selectedPrecinct" && newValue.length > 0) {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/get_precincts`,
            {
              fieldName: "Precinct_Name",
              County: newValue,
            }
          );

          if (res.data) {
            setOptionData(res.data);
          } else {
            console.log("Data is empty or undefined.");
          }
        } catch (error) {
          console.log(error);
        }
      } else if (filterName === "selectedStateHouse" && newValue.length > 0) {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/get_precincts`,
            {
              fieldName: "State_House",
              County: newValue,
            }
          );

          if (res.data) {
            setOptionData(res.data);
          } else {
            console.log("Data is empty or undefined.");
          }
        } catch (error) {
          console.log(error);
        }
      } else if (filterName === "selectedMunicipality" && newValue.length > 0) {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/get_precincts`,
            {
              fieldName: "Municipality",
              County: newValue,
            }
          );

          if (res.data) {
            setOptionData(res.data);
          } else {
            console.log("Data is empty or undefined.");
          }
        } catch (error) {
          console.log(error);
        }
      } else if (
        filterName === "selectedSchoolDistrict" &&
        newValue.length > 0
      ) {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/get_precincts`,
            {
              fieldName: "School_District",
              County: newValue,
            }
          );

          if (res.data) {
            setOptionData(res.data);
          } else {
            console.log("Data is empty or undefined.");
          }
        } catch (error) {
          console.log(error);
        }
      } else if (filterName === "selectedStateSenate" && newValue.length > 0) {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/get_precincts`,
            {
              fieldName: "State_Senate",
              County: newValue,
            }
          );

          if (res.data) {
            setOptionData(res.data);
          } else {
            console.log("Data is empty or undefined.");
          }
        } catch (error) {
          console.log(error);
        }
      } else if (filterName === "selectedMagistrate" && newValue.length > 0) {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/get_precincts`,
            {
              fieldName: "Magistrate",
              County: newValue,
            }
          );

          if (res.data) {
            setOptionData(res.data);
          } else {
            console.log("Data is empty or undefined.");
          }
        } catch (error) {
          console.log(error);
        }
      } else if (filterName === "selectedUSCongress" && newValue.length > 0) {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/get_precincts`,
            {
              fieldName: "U.S._Congress",
              County: newValue,
            }
          );

          if (res.data) {
            setOptionData(res.data);
          } else {
            console.log("Data is empty or undefined.");
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/data`
          );

          if (res.data) {
            setOptionData(res.data);
          } else {
            console.log("Data is empty or undefined.");
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    const handleRefreshFilter = () => {
      setSelectedFilters({
        selectedCounty: [],
        selectedPrecinct: [],
        selectedCongress: [],
        selectedStateSenate: [],
        selectedStateHouse: [],
        selectedMunicipality: [],
        selectedSchoolDistrict: [],
        selectedMagisterial: [],
      });
    };

    const getUniqueOptions = (filter) => {
      let uniqueOptions = [];
      switch (filter) {
        case "County":
          uniqueOptions = [...new Set(countyData.County)] || [
            ...new Set(optionData.County),
          ];
          break;
        case "Precinct":
          uniqueOptions = [...new Set(optionData.Precinct_Name)];
          break;
        case "US Congress":
          const usCongressOptions =
            optionData["U.S._Congress"] || optionData["U.S_Congress"];
          const usCongressFallbackOptions = optionData.USCongress;
          uniqueOptions = [
            ...new Set(
              usCongressOptions ? usCongressOptions : usCongressFallbackOptions
            ),
          ];
          break;
        case "State Senate":
          uniqueOptions = [...new Set(optionData.State_Senate)];
          break;
        case "State House":
          uniqueOptions = [...new Set(optionData.State_House)];
          break;
        case "Municipality":
          uniqueOptions = [...new Set(optionData.Municipality)];
          break;
        case "School District":
          uniqueOptions = [...new Set(optionData.School_District)];
          break;
        case "Magistrate":
          uniqueOptions = [...new Set(optionData.Magistrate)];
          break;
        default:
          break;
      }

      return uniqueOptions.sort((a, b) => {
        return isNaN(a) || isNaN(b) ? a.localeCompare(b) : a - b;
      });
    };

    const filterNames1 = [
      "County",
      "Precinct",
      "US Congress",
      "State Senate",
      "State House",
      "Municipality",
      "School District",
      "Magistrate",
    ];

    useImperativeHandle(ref, () => ({
      handleRefreshFilter() {
        handleRefreshFilter();
      },
    }));

    return (
      <div>
        <Card elevation={3} className="m-4">
          <CardContent>
            <Typography variant="h6" className="mb-3 pt-3">
              District
            </Typography>

            <Grid container spacing={2}>
              {/* First Column */}
              <Grid item xs={12} md={6}>
                {filterNames1
                  .slice(0, Math.ceil(filterNames1.length / 2))
                  .map((filter, index) => (
                    <FormControl key={index} variant="outlined" fullWidth>
                      <Autocomplete
                        options={getUniqueOptions(filter)}
                        value={
                          selectedFilters[
                            `selected${filter.split(" ").join("")}`
                          ]
                        }
                        onChange={(_, newValue) =>
                          handleChange(
                            `selected${filter.split(" ").join("")}`,
                            newValue
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={filter}
                            variant="outlined"
                            margin="normal"
                          />
                        )}
                        multiple={
                          filter === "County" ||
                          filter === "Precinct" ||
                          filter === "US Congress" ||
                          filter === "State Senate"
                        }
                      />
                    </FormControl>
                  ))}
                {/* <Button variant="outlined" onClick={handleRefreshFilter}>
                  Refresh Filter
                </Button> */}
              </Grid>

              {/* Second Column */}
              <Grid item xs={12} md={6}>
                {filterNames1
                  .slice(Math.ceil(filterNames1.length / 2))
                  .map((filter, index) => (
                    <FormControl key={index} variant="outlined" fullWidth>
                      <Autocomplete
                        options={getUniqueOptions(filter)}
                        value={
                          selectedFilters[
                            `selected${filter.split(" ").join("")}`
                          ]
                        }
                        onChange={(_, newValue) =>
                          handleChange(
                            `selected${filter.split(" ").join("")}`,
                            newValue
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={filter}
                            variant="outlined"
                            margin="normal"
                          />
                        )}
                        multiple={
                          filter === "Municipality" ||
                          filter === "State House" ||
                          filter === "School District" ||
                          filter === "Magistrate"
                        }
                      />
                    </FormControl>
                  ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
);

export default DistrictInfo;
