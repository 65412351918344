import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import columns from "../data/Column";
import "../App.css";
import html2pdf from "html2pdf.js";

const DataTable = ({ data }) => {
  const handleExportAllColumns = () => {
    const numberOfResults = data.length;
    const searchDate = new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const fileName = `VoterSearch_${numberOfResults}_${searchDate}.csv`;

    // Add custom logic here for exporting data with the specified file name
    // For example, you can use a library like FileSaver.js to trigger the download

    // Below is a simple example using Blob and a download link
    const csvData = [
      columns.map((column) => column.field).join(","),
      ...data.map((row) =>
        columns.map((column) => row[column.field]).join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      // Others
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const handlePrintPDF = async () => {
    try {
      setIsLoading(true);

      // Sort the data by "Street Name" for proper grouping
      const sortedData = data
        .slice()
        .sort((a, b) => a["Street_Name"].localeCompare(b["Street_Name"]));

      const groupedData = sortedData.reduce((acc, curr) => {
        if (!acc[curr["Street_Name"]]) {
          acc[curr["Street_Name"]] = {
            houses: new Set(),
          };
        }
        acc[curr["Street_Name"]].houses.add(curr["House_Number"]);
        return acc;
      }, {});

      const container = document.createElement("div");

      // Create two sections
      container.innerHTML = `
        <div style="width: 50%; float: left;" class="px-1">
          <div class="flex justify-between inset-0 border-2 border-solid border-black font-bold px-1">**Street Name/Number,Person<div>Party</div></div>
          <div id="column1"></div>
        </div>
        <div style="width: 50%; float: right;" class="px-1">
          <div class="flex justify-between inset-0 border-2 border-solid border-black font-bold px-1">**Street Name/Number,Person<div>Party</div></div>
          <div id="column2"></div>
        </div>
      `;

      // Determine the midpoint to split the data into two halves
      const midpoint = Math.ceil(sortedData.length / 2);

      // Asynchronously process the data to prevent UI freezing
      for (let index = 0; index < sortedData.length; index++) {
        const row = sortedData[index];
        const currentColumn = index < midpoint ? "column1" : "column2";

        if (
          index === 0 ||
          row["Street_Name"] !== sortedData[index - 1]["Street_Name"]
        ) {
          // Check if we need to close the previous column
          if (index > 0) {
            container.querySelector(`#${currentColumn}`).innerHTML += `</div>`;
          }

          // Determine which column to add the street name
          container.querySelector(`#${currentColumn}`).innerHTML += `
            <div>
              <strong>**${row["Street_Name"]} (${
            groupedData[row["Street_Name"]].houses.size
          } unique doors)</strong>
            </div>
            <div>
          `;
        }

        // Add person information with a two-column layout asynchronously
        container.querySelector(`#${currentColumn}`).innerHTML += `
          <div class="flex justify-between">
            <div class="fex-1">${row["House_Number"]}</div>
            <div class="fex-2">${row["First_Name"]}, ${row["Last_Name"]}</div>
            <div class="fex-1">${row["Party_Code"]}</div>
          </div>
        `;

        // If processing too many rows, yield to the event loop to prevent blocking
        if (index % 100 === 0) {
          await new Promise((resolve) => setTimeout(resolve, 0));
        }
      }

      // Close the last column
      container.querySelector(
        `#column${midpoint < sortedData.length ? "2" : "1"}`
      ).innerHTML += `</div>`;

      const pdfOptions = {
        margin: 10,
        filename: "PrintData.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
          autoTable: {
            styles: { overflow: "linebreak" },
            columnStyles: { id: { columnWidth: "auto" } },
          },
        },
      };

      html2pdf().from(container).set(pdfOptions).outputPdf().save();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="h-100 w-full overflow-auto">
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={10000}
          pagination
          disableSelectionOnClick
          disableColumnSelector
          disableDensitySelector
          getRowId={(row) => row["ID_Number"]}
          components={{
            Toolbar: () => (
              <div className="flex p-4">
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-4"
                  onClick={handleExportAllColumns}
                >
                  Export All Columns
                </button>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  onClick={handlePrintPDF}
                >
                  Print PDF
                </button>
                {isLoading && <>Downloading PDF...</>}
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default DataTable;
