import React, { useState, forwardRef, useImperativeHandle } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  FormControl,
  TextField,
} from "@mui/material";
import Select, { components } from "react-select";
import ElectionData from "../../data/ElectionData";

const VotingInfo = forwardRef(
  ({ selectedFilters, setSelectedFilters }, ref) => {
    const [optionData, setOptionData] = useState([]);

    const advancedFilters = [
      // { label: "Begins with", value: "startsWith" },
      { label: "Equals", value: "equals" },
      { label: "Between (Format Age-Age)", value: "between" },
      { label: "Less Than", value: "lessThan" },
      { label: "Greater Than", value: "greaterThan" },
    ];

    const handleChange = (filterName, newValue, selectedOption, filterType) => {
      if (filterName !== "selectedAge") {
        setSelectedFilters((prevFilters) => ({
          ...prevFilters,
          [filterName]: newValue,
        }));
      } else {
        setSelectedFilters((prevFilters) => ({
          ...prevFilters,
          [filterName]: {
            value: newValue,
            label: newValue,
            filterType: filterType || "startsWith",
          },
        }));
      }

      if (filterName === "selectedElectionOperator") {
        if (newValue === "At Least") {
          setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            selectedElectionsAtLeast: 1,
          }));
        } else {
          setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            selectedElectionsAtLeast: null,
          }));
        }
      }
    };

    function getUniqueOptions(filter) {
      switch (filter) {
        case "Status":
          return ["Active", "Inactive"];
        case "ElectionsOperator":
          return ["All", "Any", "At Least"];
        case "Age":
          return [...new Set(optionData.Age)];
        case "Elections":
          return [...new Set(ElectionData.map((item) => item.election))];
        default:
          return [];
      }
    }

    useImperativeHandle(ref, () => ({
      handleRefreshFilter() {},
    }));

    const MenuList = (props) => {
      const { maxHeight, children, innerProps } = props;
      return (
        <components.Menu {...props}>
          <div style={{ maxHeight, overflowY: "auto" }}>{children}</div>
        </components.Menu>
      );
    };

    return (
      <div>
        <Card elevation={3} className="m-4">
          <CardContent>
            <Typography variant="h6" className="mb-3 pt-3">
              Voting
            </Typography>

            <Grid container spacing={2}>
              {/* First Column */}
              <Grid item xs={12} md={6}>
                {["Status", "Age"].map((filter, index) => (
                  <FormControl key={index} variant="outlined" fullWidth>
                    {filter === "Age" && (
                      <FormControl variant="outlined" fullWidth>
                        <div className="py-5 bg-white">
                          <Select
                            options={advancedFilters}
                            isSearchable={false}
                            placeholder="Select Age"
                            components={{ MenuList }}
                            onChange={(selectedOption) =>
                              handleChange(
                                "selectedAge",
                                selectedOption.label,
                                selectedOption,
                                selectedOption.value
                              )
                            }
                          />
                        </div>
                      </FormControl>
                    )}
                    {filter === "Status" && (
                      <Autocomplete
                        options={getUniqueOptions(filter)}
                        value={selectedFilters[`selectedVoterStatus`]}
                        onChange={(_, newValue) =>
                          handleChange(`selectedVoterStatus`, newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={filter}
                            variant="outlined"
                            margin="normal"
                          />
                        )}
                        multiple={filter === "Elections" || filter === "Status"}
                      />
                    )}
                  </FormControl>
                ))}
                {/* Move "ElectionsOperator" outside of the loop */}
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    options={getUniqueOptions("ElectionsOperator")}
                    value={selectedFilters.selectedElectionOperator}
                    onChange={(_, newValue) =>
                      handleChange("selectedElectionOperator", newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Elections Operator"
                        variant="outlined"
                        margin="normal"
                      />
                    )}
                  />
                  {selectedFilters.selectedElectionOperator === "At Least" && (
                    <TextField
                      label="At Least Elections"
                      variant="outlined"
                      margin="normal"
                      type="number"
                      value={selectedFilters.selectedElectionsAtLeast}
                      onChange={(e) =>
                        handleChange("selectedElectionsAtLeast", e.target.value)
                      }
                    />
                  )}
                </FormControl>
              </Grid>
              {/* Second Column */}
              <Grid item xs={12} md={6}>
                <div className="py-10"></div>
                {["Elections"].map((filter, index) => (
                  <FormControl key={index} variant="outlined" fullWidth>
                    <TextField
                      label="Search for Age"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      value={selectedFilters.selectedAge?.value || ""}
                      onChange={(e) =>
                        handleChange(
                          "selectedAge",
                          e.target.value,
                          null,
                          selectedFilters.selectedAge?.filterType
                        )
                      }
                    />
                    {filter === "Elections" && (
                      <React.Fragment>
                        <Autocomplete
                          options={getUniqueOptions("Elections")}
                          value={selectedFilters.selectedElection}
                          onChange={(_, newValue) =>
                            handleChange("selectedElection", newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={filter}
                              variant="outlined"
                              margin="normal"
                            />
                          )}
                          multiple
                        />
                      </React.Fragment>
                    )}
                  </FormControl>
                ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
);

export default VotingInfo;
