import { Link } from "react-router-dom";

const removeDecimal = (params) => {
  const value = params.value;
  return value !== null ? value.toString().replace(".0", "") : "";
};

const columns = [
  {
    field: "ID_Number",
    headerName: "ID Number",
    width: 150,
    renderCell: (params) => (
      <Link
        to={{
          pathname: `/profile/${params.value}`,
          state: { user: params.row },
        }}
        target="_blank"
      >
        <span className="text-blue-500 hover:text-blue-700">
          {params.value}
        </span>
      </Link>
    ),
  },
  // { field: "Title", headerName: "Title", width: 100 },
  { field: "Party_Code", headerName: "Party Code", width: 120 },
  { field: "First_Name", headerName: "First Name", width: 150 },
  { field: "Last_Name", headerName: "Last Name", width: 150 },
  // { field: "Middle_Name", headerName: "Middle Name", width: 120 },
  { field: "Age", headerName: "Age", width: 80, valueGetter: removeDecimal },
  { field: "Precinct_Name", headerName: "Precinct Name", width: 150 },
  // { field: "Suffix", headerName: "Suffix", width: 100 },
  { field: "House_Number", headerName: "House Number", width: 120, valueGetter: removeDecimal },
  {
    field: "House_Number_Suffix",
    headerName: "House Number Suffix",
    width: 150,
  },
  { field: "Apartment_Number", headerName: "Apartment Number", width: 150 },
  { field: "Street_Name", headerName: "Street Name", width: 150 },
  { field: "Address_Line_2", headerName: "Address Line 2", width: 150 },
  { field: "City", headerName: "City", width: 120 },
  { field: "County", headerName: "County", width: 120 },
  // { field: "State", headerName: "State", width: 100 },
  { field: "Zip", headerName: "Zip", width: 100, valueGetter: removeDecimal },
  { field: "Status", headerName: "Support Status", width: 100 },
  
  // { field: "Gender", headerName: "Gender", width: 100 },
  // { field: "DOB", headerName: "DOB", width: 120 },
  // {
  //   field: "Home_Phone",
  //   headerName: "Home Phone",
  //   width: 150,
  //   valueGetter: removeDecimal,
  // },

  // { field: "Mail_Country", headerName: "Mail Country", width: 120 },
  // { field: "Registration_Date", headerName: "Registration Date", width: 150 },
  // { field: "Voter_Status", headerName: "Voter Status", width: 120 },
  // { field: "Status_Change_Date", headerName: "Status Change Date", width: 150 },

  // { field: "Last_Vote_Date", headerName: "Last Vote Date", width: 150 },
  // { field: 'Precinct_Code', headerName: 'Precinct Code', width: 120 },
  // { field: 'Precinct_Split_ID', headerName: 'Precinct Split ID', width: 150 },

  // { field: "Date_Last_Changed", headerName: "Date Last Changed", width: 150 },
  // { field: "Precinct", headerName: "Precinct", width: 100 },
  // { field: "U.S._Congress", headerName: "U.S. Congress", width: 150 },
  // { field: "State_Senate", headerName: "State Senate", width: 150 },
  // { field: "State_House", headerName: "State House", width: 150 },
  // { field: "Municipality", headerName: "Municipality", width: 150 },
  // { field: "School_District", headerName: "School District", width: 150 },
  // { field: "Magistrate", headerName: "Magistrate", width: 120 },
  // { field: "City_Ward", headerName: "City Ward", width: 120 },
  // {
  //   field: "2008_GENERAL_ELECTION",
  //   headerName: "2008 GENERAL ELECTION",
  //   width: 150,
  // },
  // {
  //   field: "2008_GENERAL_ELECTION_PARTY",
  //   headerName: "2008 GENERAL ELECTION Party",
  //   width: 200,
  // },
  // {
  //   field: "2008_GENERAL_PRIMARY",
  //   headerName: "2008 GENERAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2008_GENERAL_PRIMARY_PARTY",
  //   headerName: "2008 GENERAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2009_MUNICIPAL_GENERAL",
  //   headerName: "2009 MUNICIPAL GENERAL",
  //   width: 150,
  // },
  // {
  //   field: "2009_MUNICIPAL_GENERAL_PARTY",
  //   headerName: "2009 MUNICIPAL GENERAL Party",
  //   width: 200,
  // },
  // {
  //   field: "2009_MUNICIPAL_PRIMARY",
  //   headerName: "2009 MUNICIPAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2009_MUNICIPAL_PRIMARY_PARTY",
  //   headerName: "2009 MUNICIPAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2010_GENERAL_ELECTION",
  //   headerName: "2010 GENERAL ELECTION",
  //   width: 150,
  // },
  // {
  //   field: "2010_GENERAL_ELECTION_PARTY",
  //   headerName: "2010 GENERAL ELECTION Party",
  //   width: 200,
  // },
  // {
  //   field: "2010_GENERAL_PRIMARY",
  //   headerName: "2010 GENERAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2010_GENERAL_PRIMARY_PARTY",
  //   headerName: "2010 GENERAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2011_MUNICIPAL_GENERAL",
  //   headerName: "2011 MUNICIPAL GENERAL",
  //   width: 150,
  // },
  // {
  //   field: "2011_MUNICIPAL_GENERAL_PARTY",
  //   headerName: "2011 MUNICIPAL GENERAL Party",
  //   width: 200,
  // },
  // {
  //   field: "2011_MUNICIPAL_PRIMARY",
  //   headerName: "2011 MUNICIPAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2011_MUNICIPAL_PRIMARY_PARTY",
  //   headerName: "2011 MUNICIPAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2012_GENERAL_ELECTION",
  //   headerName: "2012 GENERAL ELECTION",
  //   width: 150,
  // },
  // {
  //   field: "2012_GENERAL_ELECTION_PARTY",
  //   headerName: "2012 GENERAL ELECTION Party",
  //   width: 200,
  // },
  // {
  //   field: "2012_GENERAL_PRIMARY",
  //   headerName: "2012 GENERAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2012_GENERAL_PRIMARY_PARTY",
  //   headerName: "2012 GENERAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2013_MUNICIPAL_GENERAL",
  //   headerName: "2013 MUNICIPAL GENERAL",
  //   width: 150,
  // },
  // {
  //   field: "2013_MUNICIPAL_GENERAL_PARTY",
  //   headerName: "2013 MUNICIPAL GENERAL Party",
  //   width: 200,
  // },
  // {
  //   field: "2013_MUNICIPAL_PRIMARY",
  //   headerName: "2013 MUNICIPAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2013_MUNICIPAL_PRIMARY_PARTY",
  //   headerName: "2013 MUNICIPAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2014_GENERAL_ELECTION",
  //   headerName: "2014 GENERAL ELECTION",
  //   width: 150,
  // },
  // {
  //   field: "2014_GENERAL_ELECTION_PARTY",
  //   headerName: "2014 GENERAL ELECTION Party",
  //   width: 200,
  // },
  // {
  //   field: "2014_GENERAL_PRIMARY",
  //   headerName: "2014 GENERAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2014_GENERAL_PRIMARY_PARTY",
  //   headerName: "2014 GENERAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2015_MUNICIPAL_GENERAL",
  //   headerName: "2015 MUNICIPAL GENERAL",
  //   width: 150,
  // },
  // {
  //   field: "2015_MUNICIPAL_GENERAL_PARTY",
  //   headerName: "2015 MUNICIPAL GENERAL Party",
  //   width: 200,
  // },
  // {
  //   field: "2015_MUNICIPAL_PRIMARY",
  //   headerName: "2015 MUNICIPAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2015_MUNICIPAL_PRIMARY_PARTY",
  //   headerName: "2015 MUNICIPAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2016_GENERAL_ELECTION",
  //   headerName: "2016 GENERAL ELECTION",
  //   width: 150,
  // },
  // {
  //   field: "2016_GENERAL_ELECTION_PARTY",
  //   headerName: "2016 GENERAL ELECTION Party",
  //   width: 200,
  // },
  // {
  //   field: "2016_GENERAL_PRIMARY",
  //   headerName: "2016 GENERAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2016_GENERAL_PRIMARY_PARTY",
  //   headerName: "2016 GENERAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2017_MUNICIPAL_GENERAL",
  //   headerName: "2017 MUNICIPAL GENERAL",
  //   width: 150,
  // },
  // {
  //   field: "2017_MUNICIPAL_GENERAL_PARTY",
  //   headerName: "2017 MUNICIPAL GENERAL Party",
  //   width: 200,
  // },
  // {
  //   field: "2017_MUNICIPAL_PRIMARY",
  //   headerName: "2017 MUNICIPAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2017_MUNICIPAL_PRIMARY_PARTY",
  //   headerName: "2017 MUNICIPAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2018_GENERAL_ELECTION",
  //   headerName: "2018 GENERAL ELECTION",
  //   width: 150,
  // },
  // {
  //   field: "2018_GENERAL_ELECTION_PARTY",
  //   headerName: "2018 GENERAL ELECTION Party",
  //   width: 200,
  // },
  // {
  //   field: "2018_GENERAL_PRIMARY",
  //   headerName: "2018 GENERAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2018_GENERAL_PRIMARY_PARTY",
  //   headerName: "2018 GENERAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2019_MUNICIPAL_GENERAL",
  //   headerName: "2019 MUNICIPAL GENERAL",
  //   width: 150,
  // },
  // {
  //   field: "2019_MUNICIPAL_GENERAL_PARTY",
  //   headerName: "2019 MUNICIPAL GENERAL Party",
  //   width: 200,
  // },
  // {
  //   field: "2019_MUNICIPAL_PRIMARY",
  //   headerName: "2019 MUNICIPAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2019_MUNICIPAL_PRIMARY_PARTY",
  //   headerName: "2019 MUNICIPAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2020_GENERAL_ELECTION",
  //   headerName: "2020 GENERAL ELECTION",
  //   width: 150,
  // },
  // {
  //   field: "2020_GENERAL_ELECTION_PARTY",
  //   headerName: "2020 GENERAL ELECTION Party",
  //   width: 200,
  // },
  // {
  //   field: "2020_GENERAL_PRIMARY",
  //   headerName: "2020 GENERAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2020_GENERAL_PRIMARY_PARTY",
  //   headerName: "2020 GENERAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2021_MUNICIPAL_GENERAL",
  //   headerName: "2021 MUNICIPAL GENERAL",
  //   width: 150,
  // },
  // {
  //   field: "2021_MUNICIPAL_GENERAL_PARTY",
  //   headerName: "2021 MUNICIPAL GENERAL Party",
  //   width: 200,
  // },
  // {
  //   field: "2021_MUNICIPAL_PRIMARY",
  //   headerName: "2021 MUNICIPAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2021_MUNICIPAL_PRIMARY_PARTY",
  //   headerName: "2021 MUNICIPAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2022_GENERAL_ELECTION",
  //   headerName: "2022 GENERAL ELECTION",
  //   width: 150,
  // },
  // {
  //   field: "2022_GENERAL_ELECTION_PARTY",
  //   headerName: "2022 GENERAL ELECTION Party",
  //   width: 200,
  // },
  // {
  //   field: "2022_GENERAL_PRIMARY",
  //   headerName: "2022 GENERAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2022_GENERAL_PRIMARY_PARTY",
  //   headerName: "2022 GENERAL PRIMARY Party",
  //   width: 200,
  // },
  // {
  //   field: "2023_MUNICIPAL_PRIMARY",
  //   headerName: "2023 MUNICIPAL PRIMARY",
  //   width: 150,
  // },
  // {
  //   field: "2023_MUNICIPAL_PRIMARY_PARTY",
  //   headerName: "2023 MUNICIPAL PRIMARY Party",
  //   width: 200,
  // },
];

export default columns;
