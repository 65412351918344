import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Select from "react-select";
import { Card, CardContent, Grid, FormControl, TextField } from "@mui/material";

const UserInfo = forwardRef(({ selectedFilters, setSelectedFilters }, ref) => {
  const [advancedFilters] = useState([
    { label: "Begins with", value: "startsWith" },
    { label: "Equals", value: "equals" },
    { label: "Contains", value: "contains" },
    { label: "Ends With", value: "endsWith" },
  ]);

  const handleChange = (filterName, newValue, selectedOption, filterType) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: {
        value: newValue.toUpperCase(),
        label: newValue.trim().toUpperCase(),
        filterType: filterType || "startsWith", 
      },
    }));
  };

  const filterNames4 = [
    "First Name",
    "Last Name",
    "House Number",
    "Street Name",
    "City",
    "Zip",
  ];

  useImperativeHandle(ref, () => ({
    handleRefreshFilter() {},
  }));

  return (
    <div>
      <Card elevation={3} className="m-4">
        <CardContent>
          <Grid container spacing={2}>
            {/* First Column */}
            <Grid item xs={12} md={6}>
              {filterNames4.map((filter, index) => (
                <div className="py-5">
                  <FormControl key={index} variant="outlined" fullWidth>
                    <Select
                      options={advancedFilters}
                      isSearchable={false}
                      placeholder={`Begin With ${filter} filter`}
                      onChange={(selectedOption) =>
                        handleChange(
                          `selected${filter.replace(/\s/g, "")}Filter`,
                          selectedOption.label,
                          selectedOption,
                          selectedOption.value
                        )
                      }
                    />
                  </FormControl>
                </div>
              ))}
            </Grid>
            {/* Second Column */}
            <Grid item xs={12} md={6}>
              {filterNames4.map((filter, index) => (
                <FormControl key={index} variant="outlined" fullWidth>
                  <TextField
                    label={`Search for ${filter}`}
                    variant="outlined"
                    margin="normal"
                    value={
                      selectedFilters[`selected${filter.replace(/\s/g, "")}`]?.value || ""  
                    }
                    onChange={(e) =>
                      handleChange(
                        `selected${filter.replace(/\s/g, "")}`,
                        e.target.value,
                        null,
                        selectedFilters[
                          `selected${filter.replace(/\s/g, "")}Filter`
                        ]?.filterType
                      )
                    }
                  />
                </FormControl>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
});

export default UserInfo;
