import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import data from "../data/data";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Export = () => {
  const location = useLocation();
  const exportData = location?.state?.exportData;

  const [isAllRecord, setIsAllRecord] = useState(false);
  const [isPhoneNumberOnly, setIsPhoneNumberOnly] = useState(false);
  const [isSameHouseHold, setIsSameHouseHold] = useState(false);
  const [isEmailPresent, setIsEmailPresent] = useState(false);
  const [isPhoneEmailPresent, setIsPhoneEmailPresent] = useState(false);
  const [isGisEnabled, setIsGisEnabled] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedRow, setSelectedRow] = useState(
    JSON.parse(localStorage.getItem("selectedRow")) || null
  );
  const [exports, setExports] = useState(
    JSON.parse(localStorage.getItem("savedSearches")) || []
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [progress, setProgress] = useState(0);
  const [exporting, setExporting] = useState(false);
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const updateProgressBar = (percentage) => {
    const progressBar = document.getElementById("progressBar");
    if (progressBar) {
      progressBar.style.width = `${percentage}%`;
      progressBar.innerText = `${percentage}%`;
    }
  };
  const electionFields = [
    "2008_GENERAL",
    "2008_GENERAL_PARTY",
    "2008_PRIMARY",
    "2008_PRIMARY_PARTY",
    "2009_GENERAL",
    "2009_GENERAL_PARTY",
    "2009_PRIMARY",
    "2009_PRIMARY_PARTY",
    "2010_GENERAL",
    "2010_GENERAL_PARTY",
    "2010_PRIMARY",
    "2010_PRIMARY_PARTY",
    "2011_GENERAL",
    "2011_GENERAL_PARTY",
    "2011_PRIMARY",
    "2011_PRIMARY_PARTY",
    "2012_GENERAL",
    "2012_GENERAL_PARTY",
    "2012_PRIMARY",
    "2012_PRIMARY_PARTY",
    "2013_GENERAL",
    "2013_GENERAL_PARTY",
    "2013_PRIMARY",
    "2013_PRIMARY_PARTY",
    "2014_GENERAL",
    "2014_GENERAL_PARTY",
    "2014_PRIMARY",
    "2014_PRIMARY_PARTY",
    "2015_GENERAL",
    "2015_GENERAL_PARTY",
    "2015_PRIMARY",
    "2015_PRIMARY_PARTY",
    "2016_GENERAL",
    "2016_GENERAL_PARTY",
    "2016_PRIMARY",
    "2016_PRIMARY_PARTY",
    "2017_GENERAL",
    "2017_GENERAL_PARTY",
    "2017_PRIMARY",
    "2017_PRIMARY_PARTY",
    "2018_GENERAL",
    "2018_GENERAL_PARTY",
    "2018_PRIMARY",
    "2018_PRIMARY_PARTY",
    "2019_GENERAL",
    "2019_GENERAL_PARTY",
    "2019_PRIMARY",
    "2019_PRIMARY_PARTY",
    "2020_GENERAL",
    "2020_GENERAL_PARTY",
    "2020_PRIMARY",
    "2020_PRIMARY_PARTY",
    "2021_GENERAL",
    "2021_GENERAL_PARTY",
    "2021_PRIMARY",
    "2021_PRIMARY_PARTY",
    "2022_GENERAL",
    "2022_GENERAL_PARTY",
    "2022_PRIMARY",
    "2022_PRIMARY_PARTY",
    "2023_PRIMARY",
    "2023_PRIMARY_PARTY",
  ];
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleExport = async () => {
    try {
      console.log('Email:', email);
      if (!email.trim()) {
        setSnackbarOpen(true);
        setSnackbarMessage("Please enter an email address.");
        return;
      }
      setExporting(true);
      let response;

      if (selectedRow && selectedRow.search) {
        const { search, name } = selectedRow;
        const selectedFields = search.split(",").map((field) => field.trim());

        let endpoint;
        if (isPhoneNumberOnly) {
          endpoint = "/search/export/samephone";
        } else if (isSameHouseHold) {
          endpoint = "/search/export/sameadr";
        } else if (isEmailPresent) {
          endpoint = "/search/export/sameemail";
        } else if (isPhoneEmailPresent) {
          endpoint = "/search/exportphoneemail";
        } else {
          endpoint = "/search/export";
        }

        const startTime = Date.now();
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
          JSON.stringify(exportData),
          {
            headers: {
              "Content-Type": "application/json",
            },
            onDownloadProgress: (progressEvent) => {
              const percentage = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              updateProgressBar(percentage);
            },
          }
        );

        const endTime = Date.now();
        const elapsedTime = (endTime - startTime) / 1000; // in seconds

        const responseData = response.data;

        if (responseData && responseData.data && responseData.data.length > 0) {
          const numberOfResults = responseData.count;
          const searchDate = new Date().toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          const fileName = `${name}_${numberOfResults}_${searchDate}.csv`;
          let csvData = "";
          if (isAllRecord) {
            csvData += [
              Object.keys(responseData.data[0]).join(","),
              ...responseData.data.map((row) => Object.values(row).join(",")),
            ].join("\n");
          } else if (selectedFields.length > 0) {
            csvData +=
              selectedFields
                .map((field) => {
                  if (field === "Election_History") {
                    return electionFields
                      .map((electionField) => electionField.replace(/_/g, " "))
                      .join(",");
                  } else {
                    return field;
                  }
                })
                .join(",") + "\n";
            csvData += responseData.data
              .map((row) =>
                selectedFields
                  .map((field) => {
                    if (field === "Election_History") {
                      return electionFields
                        .map((electionField) => row[electionField] || "")
                        .join(",");
                    } else if (electionFields.includes(field)) {
                      return "";
                    } else {
                      return row[field] || "";
                    }
                  })
                  .join(",")
              )
              .join("\n");
          } else {
            csvData += [
              Object.keys(responseData.data[0]).join(","),
              ...responseData.data.map((row) => Object.values(row).join(",")),
            ].join("\n");
          }

          const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
          const link = document.createElement("a");

          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }

          const result = await sendEmail(email, fileName, blob);
          setEmail("");
          setSnackbarOpen(true);

          if (result === "success") {
            setSnackbarMessage("Email sent successfully");
          } else if (result === "error") {
            setSnackbarMessage("Error sending email. Please try again later.");
          }
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage("No data to export");
        }
      } else {
        console.error("No saved search selected for export.");
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setExporting(false);
    }
  };

  const sendEmail = async (to, fileName, attachment) => {
    try {
      const formData = new FormData();
      formData.append("to", to);
      formData.append("fileName", fileName);
      formData.append("attachment", attachment);

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/send-email`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return "success";
    } catch (error) {
      console.error("Error sending email:", error);
      return "error";
    }
  };

  const getUniqueOptions = (filter) => {
    switch (filter) {
      case "Fields":
        return [...new Set(data.map((item) => item.field))];
      default:
        return [];
    }
  };

  const handleChange = (filter, newValue) => {
    setSelectedFilter(filter);

    if (filter === "Fields") {
      setSelectedFields(newValue || []);
    }
  };

  const handleSaveSearch = () => {
    if (
      selectedFilter === "Fields" &&
      selectedFields.length > 0 &&
      searchName
    ) {
      const newExport = {
        name: searchName,
        search: selectedFields.join(", "),
        lastUpdated: new Date().toLocaleString(),
      };

      setExports((prevExports) => [...prevExports, newExport]);

      localStorage.setItem(
        "savedSearches",
        JSON.stringify([...exports, newExport])
      );

      setSearchName("");
      setSelectedFields([]);
    }
  };

  const handleCheckboxChange = (checkboxName) => {
    switch (checkboxName) {
      case "isAllRecord":
        setIsAllRecord((prevIsAllRecord) => !prevIsAllRecord);
        setIsPhoneNumberOnly(false);
        setIsSameHouseHold(false);
        setIsEmailPresent(false);
        setIsPhoneEmailPresent(false);
        setIsGisEnabled(false);
        break;
      case "isPhoneNumberOnly":
        setIsAllRecord(false);
        setIsPhoneNumberOnly((prevIsPhoneNumberOnly) => !prevIsPhoneNumberOnly);
        setIsSameHouseHold(false);
        setIsEmailPresent(false);
        setIsPhoneEmailPresent(false);
        setIsGisEnabled(false);
        break;
      case "isSameHouseHold":
        setIsAllRecord(false);
        setIsPhoneNumberOnly(false);
        setIsSameHouseHold((prevIsSameHouseHold) => !prevIsSameHouseHold);
        setIsEmailPresent(false);
        setIsPhoneEmailPresent(false);
        setIsGisEnabled(false);
        break;
      case "isEmailPresent":
        setIsAllRecord(false);
        setIsPhoneNumberOnly(false);
        setIsSameHouseHold(false);
        setIsEmailPresent((prevIsEmailPresent) => !prevIsEmailPresent);
        setIsPhoneEmailPresent(false);
        setIsGisEnabled(false);
        break;
      case "isPhoneEmailPresent":
        setIsAllRecord(false);
        setIsPhoneNumberOnly(false);
        setIsSameHouseHold(false);
        setIsEmailPresent(false);
        setIsPhoneEmailPresent(
          (prevIsPhoneEmailPresent) => !prevIsPhoneEmailPresent
        );
        setIsGisEnabled(false);
        break;
      case "isGisEnabled":
        setIsAllRecord(false);
        setIsPhoneNumberOnly(false);
        setIsSameHouseHold(false);
        setIsEmailPresent(false);
        setIsPhoneEmailPresent(false);
        setIsGisEnabled((prevIsGisEnabled) => !prevIsGisEnabled);
        break;
      default:
        break;
    }
  };

  const handleDragStartFields = () => {
    document.body.style.cursor = "grabbing";
    document.body.style.userSelect = "none";
  };

  const handleDragEndFields = (result) => {
    document.body.style.cursor = "auto";
    document.body.style.userSelect = "auto";

    if (!result.destination) {
      return;
    }

    const reorderedFields = Array.from(selectedFields);
    const [removed] = reorderedFields.splice(result.source.index, 1);
    reorderedFields.splice(result.destination.index, 0, removed);

    setSelectedFields(reorderedFields);
  };

  const handleDragUpdateFields = (result) => {
    if (result.destination) {
      const reorderedFields = Array.from(selectedFields);
      const [removed] = reorderedFields.splice(result.source.index, 1);
      reorderedFields.splice(result.destination.index, 0, removed);

      setSelectedFields(reorderedFields);
    }
  };
  const [isEditing, setIsEditing] = useState(false);

  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  const toggleEditMode = (index) => {
    setIsEditing(!isEditing);
    setSelectedRowIndex(index);
    if (!isEditing) {
      setSearchName("");
      setSelectedFields([]);
    }
  };

  const handleSaveEdit = () => {
    const updatedExports = exports.map((exportItem, index) => {
      if (index === selectedRowIndex) {
        return {
          ...exportItem,
          name: searchName,
          search: selectedFields.join(", "),
          lastUpdated: new Date().toLocaleString(),
        };
      }
      return exportItem;
    });

    setExports(updatedExports);
    localStorage.setItem("savedSearches", JSON.stringify(updatedExports));

    toggleEditMode(selectedRowIndex);
  };

  const handleCancelEdit = (index) => {
    toggleEditMode(index);
    setSelectedFields([]);
    setSearchName("");
  };
  const handleCancel = () => {
    setSelectedFields([]);
    setSearchName("");
  };

  const handleEditExport = (index) => {
    const exportToEdit = exports[index];
    setSelectedRowIndex(index);
    setSearchName(exportToEdit.name);
    setSelectedFields(exportToEdit.search.split(", "));
    toggleEditMode(index);
    console.log(exportToEdit);
  };

  const handleDeleteExport = (index) => {
    const updatedExports = [...exports];
    updatedExports.splice(index, 1);

    setExports(updatedExports);

    localStorage.setItem("savedSearches", JSON.stringify(updatedExports));
  };
  const handleRowClick = (row) => {
    setSelectedRow(row);
    localStorage.setItem("selectedRow", JSON.stringify(row));
  };
  useEffect(() => {
    if (selectedRowIndex !== -1) {
      const exportToEdit = exports[selectedRowIndex];
      setSearchName(exportToEdit.name);
      setSelectedFields(exportToEdit.search.split(", "));
    }
  }, [selectedRowIndex, exports]);
  useEffect(() => {
    if (exports.length === 0) {
      const allFields = data.map((item) => item.field);
      const defaultProfile = {
        name: "Default Profile",
        search: allFields.join(", "),
        lastUpdated: new Date().toLocaleString(),
      };
      setExports([defaultProfile]);
      localStorage.setItem("savedSearches", JSON.stringify([defaultProfile]));
    }
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <div className="flex space-x-4">
        <div className="flex-1 bg-gray-100 p-6">
          <Card elevation={3} className="w-full">
            <CardContent>
              <Typography variant="h6" className="mb-4">
                New Export
              </Typography>
              {/* <div className="mb-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAllRecord}
                      onChange={() => handleCheckboxChange("isAllRecord")}
                    />
                  }
                  label="All Records"
                />
              </div> */}
              <div className="mb-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPhoneNumberOnly}
                      onChange={() => handleCheckboxChange("isPhoneNumberOnly")}
                    />
                  }
                  label="Only records with phone numbers"
                />
              </div>
              <div className="mb-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isEmailPresent}
                      onChange={() => handleCheckboxChange("isEmailPresent")}
                    />
                  }
                  label="Only records with email"
                />
              </div>
              {/* <div className="mb-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPhoneEmailPresent}
                      onChange={() =>
                        handleCheckboxChange("isPhoneEmailPresent")
                      }
                    />
                  }
                  label="Records with additional phone number and email"
                />
              </div> */}
              <div className="mb-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSameHouseHold}
                      onChange={() => handleCheckboxChange("isSameHouseHold")}
                    />
                  }
                  label="Include other members in the same household"
                />
              </div>
              <div className="mb-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isGisEnabled}
                      onChange={() => handleCheckboxChange("isGisEnabled")}
                    />
                  }
                  label="Get Lat and Longitude of each address (GIS)"
                />
              </div>

              <TableContainer className="my-4">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Export Profile</TableCell>
                      <TableCell>Last Updated</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {exports.map((exportItem, index) => (
                      <TableRow
                        key={index}
                        onClick={() => handleRowClick(exportItem)}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedRow === exportItem
                              ? "#a0d3e8"
                              : "transparent",
                        }}
                      >
                        <TableCell>{exportItem.name}</TableCell>
                        <TableCell>{exportItem.lastUpdated}</TableCell>
                        <TableCell>
                          {exportItem.name !== "Default Profile" && ( // Conditionally render buttons for non-default profiles
                            <>
                              <Button
                                variant="outlined"
                                onClick={() => handleEditExport(index)}
                              >
                                Edit
                              </Button>
                              <i className="px-2"></i>
                              <Button
                                variant="outlined"
                                onClick={() => handleDeleteExport(index)}
                              >
                                Delete
                              </Button>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className="flex space-x-4">
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter email address"
                  required 
                />
                <Button
                  variant="contained"
                  color="primary"
                  halfWidth
                  onClick={handleExport}
                >
                  Export
                </Button>
                {exporting && (
                  <div className="progress">
                    <div
                      id="progressBar"
                      className="progress-bar text-xl font-semibold rounded-full justify-center"
                      role="progressbar"
                      style={{ width: `${progress}%` }}
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {`${progress}%`}
                    </div>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="flex-1 bg-gray-100 p-6 space-y-3">
          <Card elevation={3} className="w-full ">
            <CardContent>
              <div className="space-y-5">
                <Typography variant="h6" className="mb-4">
                  {isEditing ? "Edit Export Profile" : "Create Export Profile"}
                </Typography>
                {isEditing ? (
                  <>
                    <TextField
                      label="Profile Name"
                      variant="outlined"
                      fullWidth
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                    />
                    <FormControl variant="outlined" fullWidth>
                      <Autocomplete
                        options={data.map((item) => item.field)}
                        value={selectedFields}
                        onChange={(event, newValue) =>
                          setSelectedFields(newValue)
                        }
                        disableListWrap
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Fields"
                            variant="outlined"
                            margin="normal"
                            inputProps={{
                              ...params.inputProps,
                              "data-rbd-drag-handle-draggable-id":
                                "draggable-autocomplete",
                              "data-rbd-drag-handle": true,
                            }}
                          />
                        )}
                        multiple
                        disableCloseOnSelect
                      />
                    </FormControl>
                    <DragDropContext
                      onDragStart={handleDragStartFields}
                      onDragEnd={handleDragEndFields}
                      onDragUpdate={handleDragUpdateFields}
                    >
                      <Droppable
                        droppableId="droppable-fields"
                        direction="horizontal"
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              maxWidth: "100%",
                              width: "fit-content",
                              maxWidth: "100%",
                              overflow: "hidden",
                            }}
                          >
                            {selectedFields.map((field, index) => (
                              <Draggable
                                key={field}
                                draggableId={field}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      margin: "5px 5px",
                                      backgroundColor: snapshot.isDragging
                                        ? "lightblue"
                                        : "lightgray",
                                      padding: "8px",
                                      borderRadius: "4px",
                                      cursor: "grab",
                                      transition: "background-color 0.2s",
                                      userSelect: "none",
                                      display: "flex",
                                      position: "relative",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                      width: "fit-content",
                                      maxWidth: "100%",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {field}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <div className="space-x-4 mt-4">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveEdit}
                      >
                        Save
                      </Button>
                      <Button variant="outlined" onClick={handleCancel}>
                        Reset
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => handleCancelEdit(selectedRowIndex)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <TextField
                      label="Create Export Profile"
                      variant="outlined"
                      fullWidth
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                    />

                    <FormControl variant="outlined" fullWidth>
                      <Autocomplete
                        options={getUniqueOptions("Fields")}
                        value={
                          selectedFilter === "Fields" ? selectedFields : []
                        }
                        onChange={(_, newValue) =>
                          handleChange("Fields", newValue)
                        }
                        disableListWrap
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Fields"
                            variant="outlined"
                            margin="normal"
                            inputProps={{
                              ...params.inputProps,
                              "data-rbd-drag-handle-draggable-id":
                                "draggable-autocomplete",
                              "data-rbd-drag-handle": true,
                            }}
                          />
                        )}
                        multiple
                        disableCloseOnSelect
                      />
                    </FormControl>
                    <DragDropContext
                      onDragStart={handleDragStartFields}
                      onDragEnd={handleDragEndFields}
                      onDragUpdate={handleDragUpdateFields}
                    >
                      <Droppable
                        droppableId="droppable-fields"
                        direction="horizontal"
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              maxWidth: "100%",
                              width: "fit-content",
                              maxWidth: "100%",
                              overflow: "hidden",
                            }}
                          >
                            {selectedFields.map((field, index) => (
                              <Draggable
                                key={field}
                                draggableId={field}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      margin: "5px 5px",
                                      backgroundColor: snapshot.isDragging
                                        ? "lightblue"
                                        : "lightgray",
                                      padding: "8px",
                                      borderRadius: "4px",
                                      cursor: "grab",
                                      transition: "background-color 0.2s",
                                      userSelect: "none",
                                      display: "flex",
                                      position: "relative",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                      width: "fit-content",
                                      maxWidth: "100%",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {field}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <Button
                      variant="contained"
                      color="primary"
                      halfWidth
                      onClick={handleSaveSearch}
                    >
                      Save
                    </Button>
                    <i className="px-2"></i>
                    <Button
                      variant="outlined"
                      color="primary"
                      halfWidth
                      onClick={handleCancel}
                    >
                      Reset
                    </Button>
                  </>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Export;