const data = [
  { field: "ID_Number" },
  { field: "First_Name" },
  { field: "Last_Name" },
  { field: "Age" },
  { field: "Party_Code" },
  { field: "Status" },
  { field: "County" },
  { field: "City" },
  { field: "Street_Name" },
  { field: "House_Number" },
  { field: "House_Number_Suffix" },
  { field: "Apartment_Number" },
  { field: "Address_Line_2" },
  { field: "Zip" },
  { field: "Precinct_Name" },
  { field: "Title" },
  { field: "Middle_Name" },
  { field: "Suffix" },
  { field: "Gender" },
  { field: "DOB" },
  { field: "Home_Phone" },
  { field: "Mail_Country" },
  { field: "Registration_Date" },
  { field: "Voter_Status" },
  { field: "Status_Change_Date" },
  { field: "Last_Vote_Date" },
  { field: "Precinct_Code" },
  { field: "Precinct_Split_ID" },
  { field: "Date_Last_Changed" },
  { field: "Precinct" },
  { field: "U.S._Congress" },
  { field: "State_Senate" },
  { field: "State_House" },
  { field: "Municipality" },
  { field: "School_District" },
  { field: "Magistrate" },
  { field: "City_Ward" },
  // Election History field and related election fields
  { field: "Election_History", relatedFields: [
      "2008_GENERAL",
      "2008_GENERAL_PARTY",
      "2008_PRIMARY",
      "2008_PRIMARY_PARTY",
      "2009_GENERAL",
      "2009_GENERAL_PARTY",
      "2009_PRIMARY",
      "2009_PRIMARY_PARTY",
      "2010_GENERAL",
      "2010_GENERAL_PARTY",
      "2010_PRIMARY",
      "2010_PRIMARY_PARTY",
      // Include more election fields as needed
    ]
  }
];

export default data;
