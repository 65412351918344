import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Select, { components } from "react-select";
import {
  Button,
  Card,
  CardContent,
  Grid,
  FormControl,
  TextField,
} from "@mui/material";
import StatusData from "../../data/StatusData";
import PartyData from "../../data/PartyData";

const VoterInfo = forwardRef(
  ({ onDistrictData, selectedFilters, setSelectedFilters }, ref) => {
    const [optionData, setOptionData] = useState([]);
    const [advancedFilters] = useState([
      { label: "Begins with", value: "startsWith" },
      { label: "Equals", value: "equals" },
      { label: "Contains", value: "contains" },
      { label: "Ends With", value: "endsWith" },
    ]);

    useEffect(() => {
      fetchOptionData();
    }, []);

    const fetchOptionData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/data`
        );

        if (res.data) {
          setOptionData(res.data);
        } else {
          console.log("Data is empty or undefined.");
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleChange = (filterName, newValue, selectedOption, filterType) => {
      if (
        filterName !== "selectedIDNumber" 
      ) {
        setSelectedFilters((prevFilters) => ({
          ...prevFilters,
          [filterName]: newValue,
        }));
      } else if (filterName === "selectedGender") {
        setSelectedFilters((prevFilters) => ({
          ...prevFilters,
          [filterName]: newValue ? newValue : ["Male", "Female"],
        }));
      } else {
        setSelectedFilters((prevFilters) => ({
          ...prevFilters,
          [filterName]: {
            value: newValue,
            label: newValue,
            filterType: filterType || "startsWith",
          },
        }));
      }
    };

    const getUniqueOptions = (filter) => {
      switch (filter) {
        case "Voter ID":
          return [...new Set(optionData.IDNumber)];
        case "Party":
          return [...new Set(PartyData.map((item) => item.party))];
        case "Gender":
          return [...new Set(optionData.Gender)];
        case "House With":
          return [...new Set(optionData.HouseNumber)];
        case "Support Status":
          return [...new Set(StatusData.map((item) => item.status))];
        default:
          return [];
      }
    };

    const filterNames4 = [
      "Voter ID",
      "Party",
      "Gender",
      "House With",
      "Support Status",
    ];

    useImperativeHandle(ref, () => ({
      handleRefreshFilter() {},
    }));
    const MenuList = (props) => {
      return (
        <components.MenuList {...props}>
          <div
            style={{
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {props.children}
          </div>
        </components.MenuList>
      );
    };

    return (
      <div>
        <Card elevation={3} className="m-4">
          <CardContent>
            <Grid container spacing={2}>
              {/* First Column */}
              <Grid item xs={12} md={6}>
                <div className="py-4">
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                      options={advancedFilters}
                      isSearchable={false}
                      placeholder="Select Volter Id"
                      components={{ MenuList }}
                      onChange={(selectedOption) =>
                        handleChange(
                          "selectedIDNumber",
                          selectedOption.label,
                          selectedOption,
                          selectedOption.value
                        )
                      }
                    />
                  </FormControl>
                </div>

                {filterNames4
                  .filter((filter) => filter === "Party" || filter === "Gender")
                  .map((filter, index) => (
                    <FormControl key={index} variant="outlined" fullWidth>
                      <Autocomplete
                        options={getUniqueOptions(filter)}
                        value={
                          selectedFilters[
                            `selected${filter.split(" ").join("")}`
                          ]
                        }
                        onChange={(_, newValue) =>
                          handleChange(
                            `selected${filter.split(" ").join("")}`,
                            newValue
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={filter}
                            variant="outlined"
                            margin="normal"
                          />
                        )}
                        multiple={
                          filter === "Party" ||
                          filter === "Gender" ||
                          filter === "House With" ||
                          filter === "Support Status"
                        }
                      />
                    </FormControl>
                  ))}
              </Grid>

              {/* Second Column */}
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Search for Voter ID"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    value={selectedFilters.selectedIDNumber?.value || ""}
                    onChange={(e) =>
                      handleChange(
                        "selectedIDNumber",
                        e.target.value,
                        null,
                        selectedFilters.selectedIDNumber?.filterType
                      )
                    }
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                    options={advancedFilters}
                    isSearchable={false}
                    placeholder="House With"
                    components={{ MenuList }}
                    onChange={(selectedOption) =>
                      handleChange(
                        "selectedHouseWith",
                        selectedOption.label,
                        selectedOption,
                        selectedOption.value
                      )
                    }
                  />
                  <TextField
                    label="House With"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    value={selectedFilters.selectedHouseWith?.value }
                    onChange={(e) =>
                      handleChange(
                        "selectedHouseWith",
                        e.target.value,
                        null,
                        selectedFilters.selectedHouseWith?.filterType
                      )
                    }
                  />
                </FormControl>
                {filterNames4
                  .filter((filter) => filter === "Support Status")
                  .map((filter, index) => (
                    <FormControl key={index} variant="outlined" fullWidth>
                      <Autocomplete
                        options={getUniqueOptions(filter)}
                        value={
                          selectedFilters[
                            `selected${filter.split(" ").join("")}`
                          ]
                        }
                        onChange={(_, newValue) =>
                          handleChange(
                            `selected${filter.split(" ").join("")}`,
                            newValue
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={filter}
                            variant="outlined"
                            margin="normal"
                          />
                        )}
                        multiple={
                          filter === "Party" ||
                          filter === "Gender" ||
                          filter === "House With" ||
                          filter === "Support Status"
                        }
                      />
                    </FormControl>
                  ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
);

export default VoterInfo;
