import React from "react";
import Statistics from "../components/Statistics";
import { useLocation } from "react-router-dom";
import DataTable from "../components/TableComponent";

const Home = () => {
  const location = useLocation();
  const districtData = location.state?.districtData || [];

  return (
    <div>
      <Statistics />
      <div className="p-4">
        <DataTable data={districtData} />
      </div>
    </div>
  );
};

export default Home;
