import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import VotingInfo from "../components/Filter/VotingInfo";
import DistrictInfo from "../components/Filter/DistrictInfo";
import UserInfo from "../components/Filter/UserInfo";
import VoterInfo from "../components/Filter/VoterInfo";
import {
  Button,
  Card,
  Grid,
  CircularProgress,
  Typography,
  Snackbar,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import axios from "axios";
import DataTable from "../components/TableComponent";
import Statistics from "../components/Statistics";

const Filter = () => {
  const navigate = useNavigate();
  const [districtData, setDistrictData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isError, setisError] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    selectedCounty: [],
    selectedPrecinct: [],
    selectedUSCongress: [],
    selectedStateSenate: [],
    selectedStateHouse: [],
    selectedMunicipality: [],
    selectedSchoolDistrict: [],
    selectedMagistrate: [],
    selectedFirstName: [],
    selectedLastName: [],
    selectedHouseNumber: [],
    selectedStreetName: [],
    selectedCity: [],
    selectedZip: [],
    selectedIDNumber: [],
    selectedHouseWith: [],
    selectedParty: [],
    selectedGender: ["Male", "Female", "Unknown"],
    selectedSupportStatus: [],
    selectedVoterStatus: ["Active"],
    selectedAge: "",
    selectedElection: [],
    selectedElectionOperator: "",
  });

  const handleDistrictData = (data) => {
    setDistrictData(data);
  };

  const handleRefreshFilter = () => {
    setSelectedFilters({
      selectedCounty: [],
      selectedPrecinct: [],
      selectedUSCongress: [],
      selectedStateSenate: [],
      selectedStateHouse: [],
      selectedMunicipality: [],
      selectedSchoolDistrict: [],
      selectedMagistrate: [],
      selectedFirstName: [],
      selectedLastName: [],
      selectedHouseNumber: [],
      selectedStreetName: [],
      selectedCity: [],
      selectedZip: [],
      selectedIDNumber: [],
      selectedHouseWith: [],
      selectedParty: [],
      selectedGender: ["Male", "Female", "Unknown"],
      selectedSupportStatus: [],
      selectedVoterStatus: ["Active"],
      selectedAge: "",
      selectedElection: [],
    });

    setDistrictData([]);
    setLoading(false);
  };
  const [loading, setLoading] = useState(false);
  const [countData, setCountData] = useState({});
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const renamedFilters = {
        County: selectedFilters.selectedCounty,
        Precinct_Name: selectedFilters.selectedPrecinct,
        "U.S._Congress": selectedFilters.selectedUSCongress,
        State_Senate: selectedFilters.selectedStateSenate,
        State_House: selectedFilters.selectedStateHouse,
        Municipality: selectedFilters.selectedMunicipality,
        School_District: selectedFilters.selectedSchoolDistrict,
        Magistrate: selectedFilters.selectedMagistrate,
        First_Name: selectedFilters.selectedFirstName,
        Last_Name: selectedFilters.selectedLastName,
        House_Number: selectedFilters.selectedHouseNumber,
        City: selectedFilters.selectedCity,
        Street_Name: selectedFilters.selectedStreetName,
        Zip: selectedFilters.selectedZip,
        ID_Number: selectedFilters.selectedIDNumber,
        Party_Code: selectedFilters.selectedParty,
        Gender: selectedFilters.selectedGender,
        Age: selectedFilters.selectedAge,
        Voter_Status: selectedFilters.selectedVoterStatus,
        Election: selectedFilters.selectedElection,
        Status: selectedFilters.selectedSupportStatus,
        HouseWith: selectedFilters.selectedHouseWith,
      };

      console.log(renamedFilters);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/search`,
        renamedFilters
      );

      const rowsWithId = response.data.data.map((row, index) => ({
        ...row,
        id: row.ID_Number,
      }));

      setDistrictData(rowsWithId);
    } catch (error) {
      setisError(true);
      setErrorMessage("Somthing went wrong please try again");
      setisError(false);
      console.error("Error fetching district data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    try {
      const renamedFilters = {
        County: selectedFilters.selectedCounty,
        Precinct_Name: selectedFilters.selectedPrecinct,
        "U.S._Congress": selectedFilters.selectedUSCongress,
        State_Senate: selectedFilters.selectedStateSenate,
        State_House: selectedFilters.selectedStateHouse,
        Municipality: selectedFilters.selectedMunicipality,
        School_District: selectedFilters.selectedSchoolDistrict,
        Magistrate: selectedFilters.selectedMagistrate,
        First_Name: selectedFilters.selectedFirstName,
        Last_Name: selectedFilters.selectedLastName,
        House_Number: selectedFilters.selectedHouseNumber,
        City: selectedFilters.selectedCity,
        Street_Name: selectedFilters.selectedStreetName,
        Zip: selectedFilters.selectedZip,
        ID_Number: selectedFilters.selectedIDNumber,
        Party_Code: selectedFilters.selectedParty,
        Gender: selectedFilters.selectedGender,
        Age: selectedFilters.selectedAge,
        Voter_Status: selectedFilters.selectedVoterStatus,
        Election: selectedFilters.selectedElection,
        Status: selectedFilters.selectedSupportStatus,
        HouseWith: selectedFilters.selectedHouseWith,
      };

      console.log(renamedFilters);
      navigate("/export", { state: { exportData: renamedFilters } });
    } catch (error) {
      setisError(true);
      setErrorMessage("Somthing went wrong please try again");
      setisError(false);
      console.error("Error fetching district data:", error);
    }
  };

  const [open, setOpen] = useState(false);
  const [counts, setCounts] = useState(null);
  const [countLoading, setCountLoading] = useState(false);

  const handleCount = async () => {
    setCountLoading(true);
    try {
      const renamedFilters = {
        County: selectedFilters.selectedCounty,
        Precinct_Name: selectedFilters.selectedPrecinct,
        "U.S._Congress": selectedFilters.selectedUSCongress,
        State_Senate: selectedFilters.selectedStateSenate,
        State_House: selectedFilters.selectedStateHouse,
        Municipality: selectedFilters.selectedMunicipality,
        School_District: selectedFilters.selectedSchoolDistrict,
        Magistrate: selectedFilters.selectedMagistrate,
        First_Name: selectedFilters.selectedFirstName,
        Last_Name: selectedFilters.selectedLastName,
        House_Number: selectedFilters.selectedHouseNumber,
        City: selectedFilters.selectedCity,
        Street_Name: selectedFilters.selectedStreetName,
        Zip: selectedFilters.selectedZip,
        ID_Number: selectedFilters.selectedIDNumber,
        Party_Code: selectedFilters.selectedParty,
        Gender: selectedFilters.selectedGender,
        Age: selectedFilters.selectedAge,
        Voter_Status: selectedFilters.selectedVoterStatus,
        Election: selectedFilters.selectedElection,
        Status: selectedFilters.selectedSupportStatus,
        HouseWith: selectedFilters.selectedHouseWith,
        // Election_Operator: selectedFilters.selectedElectionOperator,
      };

      console.log(renamedFilters);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/count`,
        renamedFilters
      );
      console.log(response);
      const data = response.data;
      const totalGenderCount = Object.values(data.gender_count).reduce(
        (total, count) => total + count,
        0
      );

      data.total_count = totalGenderCount;
      setCounts(data);

      setOpen(true);
    } catch (e) {
      console.log(e);
    } finally {
      setCountLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="flex">
        <Snackbar
          open={isError}
          onClose={() => setisError(false)}
          style={{ backgroundColor: "red", color: "white" }}
          autoHideDuration={2000}
          message={errorMessage}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
        <div className="flex-1">
          <DistrictInfo
            onDistrictData={handleDistrictData}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
          <VoterInfo
            onDistrictData={handleDistrictData}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </div>
        <div className="flex-1">
          <VotingInfo
            onDistrictData={handleDistrictData}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
          <UserInfo
            onDistrictData={handleDistrictData}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </div>
      </div>
      <Card elevation={3} className="m-4 p-4 flex justify-between">
        <Button variant="outlined" onClick={handleRefreshFilter}>
          Refresh Filter
        </Button>
        <div>
          <Button variant="outlined" onClick={handleSubmit}>
            Submit
          </Button>
          <i className="px-2" />
          <Button variant="outlined" onClick={handleCount}>
            {countLoading ? "Counting..." : "Count"}
          </Button>
          <i className="px-2" />
          <Button variant="outlined" onClick={handleExport}>
            Export
          </Button>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-md w-96">
                <h2
                  id="transition-modal-title"
                  className="text-2xl font-bold mb-4"
                >
                  Counts
                </h2>
                <div id="transition-modal-description">
                  {counts && (
                    <>
                      <p className="mb-2">
                        <span className="font-semibold">Total Count:</span>{" "}
                        {counts?.total_count.toLocaleString()}
                      </p>
                      <p className="mb-2">
                        <span className="font-semibold">City Count:</span>{" "}
                        {counts.city_count.toLocaleString()}
                      </p>

                      <p className="mb-2">
                        <span className="font-semibold">
                          Phone Number Count:
                        </span>{" "}
                        {counts.homephone_count.toLocaleString()}
                      </p>
                      <p className="mb-2">
                        <span className="font-semibold">Gender Count:</span>{" "}
                        {Object.entries(counts.gender_count).map(
                          ([gender, count]) => (
                            <p key={gender} className="ml-2">
                              <span className="font-semibold">{gender}</span>:{" "}
                              {count.toLocaleString()}
                            </p>
                          )
                        )}
                      </p>
                      <p className="mb-2">
                        <span className="font-semibold">Party Count:</span>{" "}
                        {Object.entries(counts.party_count).map(
                          ([party, count]) => (
                            <p key={party} className="ml-2">
                              {party !== "At Polls" && (
                                <>
                                  <span className="font-semibold">{party}</span>
                                  : {count.toLocaleString()}
                                </>
                              )}
                            </p>
                          )
                        )}
                      </p>
                      {counts.unique_address < 10000 && (
                        <p className="mb-2">
                          <span className="font-semibold">
                            Unique Address Count:
                          </span>{" "}
                          {counts.unique_address.toLocaleString()}
                        </p>
                      )}
                    </>
                  )}
                </div>

                <Button
                  variant="outlined"
                  className="mt-4 w-full"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
        {/* {loading && (
          <CircularProgress
            size={24}
            style={{ marginLeft: 10, color: "green" }}
          />
        )} */}
      </Card>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <CircularProgress />
          <Typography variant="body2" color="textSecondary" component="p">
            Loading district data...
          </Typography>
        </div>
      ) : (
        <>
          {districtData.length >= 1 ? (
            <div>
              <Statistics data={districtData} />
              <div className="p-4">
                {districtData.length >= 5000 ? (
                  <p className="py-2 px-2 text-sm bg-red-500 text-white">
                    Warning: Your results exceeded 5,000 records, we are only
                    showing the first 5,000
                  </p>
                ) : (
                  <></>
                )}
                <DataTable data={districtData} />
              </div>
            </div>
          ) : (
            <>No Result</>
          )}
        </>
      )}
    </div>
  );
};

export default Filter;
