const ElectionData = [
  { election: "2023_PRIMARY" },
  { election: "2022_PRIMARY" },
  { election: "2022_GENERAL" },
  { election: "2021_PRIMARY" },
  { election: "2021_GENERAL" },
  { election: "2020_PRIMARY" },
  { election: "2020_GENERAL" },
  { election: "2019_PRIMARY" },
  { election: "2019_GENERAL" },
  { election: "2018_PRIMARY" },
  { election: "2018_GENERAL" },
  { election: "2017_PRIMARY" },
  { election: "2017_GENERAL" },
  { election: "2016_PRIMARY" },
  { election: "2016_GENERAL" },
  { election: "2015_PRIMARY" },
  { election: "2015_GENERAL" },
  { election: "2014_PRIMARY" },
  { election: "2014_GENERAL" },
  { election: "2013_PRIMARY" },
  { election: "2013_GENERAL" },
  { election: "2012_PRIMARY" },
  { election: "2012_GENERAL" },
  { election: "2011_PRIMARY" },
  { election: "2011_GENERAL" },
  { election: "2010_PRIMARY" },
  { election: "2010_GENERAL" },
  { election: "2009_PRIMARY" },
  { election: "2009_GENERAL" },
  { election: "2008_PRIMARY" },
  { election: "2008_GENERAL" },
];

export default ElectionData;
