import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

const ElectionDataTable = ({ data }) => {
  const electionYears = [
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
  ];

  const tableRows = electionYears.map((year) => {
    let primaryValue =
      data[`${year}_PRIMARY_PARTY`] || data[`${year}_GENERAL_PARTY`];
    let generalValue = data[`${year}_PRIMARY`] || data[`${year}_GENERAL`];

    if (!primaryValue) {
      primaryValue = "-";
    }

    if (!generalValue) {
      generalValue = "-";
    }

    return (
      <TableRow key={year}>
        <TableCell>{primaryValue}</TableCell>
        <TableCell>{year}</TableCell>
        <TableCell>{generalValue}</TableCell>
      </TableRow>
    );
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h7" className="font-bold">
                Primary
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h7" className="font-bold">
                Year
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h7" className="font-bold">
                General
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ElectionDataTable;
