import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TableContainer,
  Button,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import ElectionDataTable from "../components/ElectionTable";
import DataTable from "../components/TableComponent";
import FileSaver from "file-saver";

const Profile = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [supportStatus, setSupportStatus] = useState(null);
  const tableRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSupportStatusReset = () => {
    setSelectedOption(null);
    updateStatus("");
  };

  const handleClick = (event, option) => {
    switch (event.detail) {
      case 1: {
        break;
      }
      case 2: {
        console.log("double click");
        if (selectedOption === option) {
          setSelectedOption(null);
        } else {
          setSelectedOption(option);
          updateStatus(option);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/get_user_data/${id}`
      );
      console.log("data", res.data);

      if (res.data) {
        setData(res.data);
        loadCount();
      } else {
        console.log("Data is empty or undefined.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateStatus = async (status) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/update_status`,
        {
          voter_ID: id,
          status: status,
        }
      );

      const newindex = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/newindex_status`,
        {
          voter_ID: id,
          status: status,
        }
      );
      console.log(newindex.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSupportStatus = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/get_status/${id}`
      );
      setSelectedOption(res.data.Status);
    } catch (error) {
      console.log(error);
    }
  };

  const [democratVoters, setDemocratVoters] = useState([]);
  const [showDemocratDataTable, setShowDemocratDataTable] = useState(false);
  const [DemocratCount, setDemocratCount] = useState(0);
  const [RepublicanCount, setRepublicanCount] = useState(0);
  const [TotalCount, setTotalCount] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loadingTableData, setLoadingTableData] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCopyToClipboardVCF = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/download_vcard/${data.ID_Number}`,
        { responseType: "blob" }
      );

      // Create Blob from response data
      const blob = new Blob([response.data], { type: "text/vcard" });

      // Save Blob to a file using FileSaver.js
      FileSaver.saveAs(blob, `${data.ID_Number}_vcard.vcf`);

      // Optional: Copy to clipboard using navigator.clipboard.write
      const text = await response.data.text();
      await navigator.clipboard.writeText(text);

      console.log("vCard downloaded and copied to clipboard successfully");
    } catch (error) {
      console.error("Error during vCard download:", error);
    }
  };

  const handleDownload = (type) => {
    if (type === "CSV") {
      handleCopyToClipboard();
    } else {
      console.log(`Download ${type}`);
    }
  };
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const handleCopyToClipboard = () => {
    const commaDelimitedFields = [
      "ID_Number",
      "County",
      "First_Name",
      "Last_Name",
      "Middle_Name",
      "Suffix",
      "Gender",
      "Party",
      "House_Number",
      "Street_Name",
      "Apartment_Number",
      "Address_Line_2",
      "City",
      "State",
      "Zip",
      "Home_Phone",
      "Email",
    ];

    const headerRow = commaDelimitedFields.join(",") + "\n";

    const dataRow =
      commaDelimitedFields.map((field) => `"${data[field] || ""}"`).join(",") +
      "\n";
    // const dataRow =
    //   commaDelimitedFields
    //     .map((field) => {
    //       let value = data[field];
    //       if (value === null || value === "") {
    //         return "";
    //       }
    //       if (!isNaN(value) && Number.isInteger(Number(value))) {
    //         value = Number(value).toString();
    //       }
    //       return `"${value}"`;
    //     })
    //     .join(",") + "\n";

    const csvData = dataRow;

    navigator.clipboard.writeText(csvData);

    setSnackbarOpen(true);
  };

  const handleDemocratVoterLink = async () => {
    try {
      setLoadingTableData(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/search/democrat`,
        {
          filters: {
            Street_Name: data?.Street_Name,
            House_Number: data?.House_Number,
            Precinct_Name: data?.Precinct_Name,
          },
        }
      );

      if (res.data && res.data.data) {
        setDemocratVoters(res.data.data);
        setShowDemocratDataTable(true);
      } else {
        console.log("Democrat Voter Data is empty or undefined.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTableData(false);
    }
  };
  const handleRepublicianVoterLink = async () => {
    try {
      setLoadingTableData(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/search/republician`,
        {
          filters: {
            Street_Name: data?.Street_Name,
            House_Number: data?.House_Number,
            Precinct_Name: data?.Precinct_Name,
          },
        }
      );

      if (res.data && res.data.data) {
        setDemocratVoters(res.data.data);
        setShowDemocratDataTable(true);
      } else {
        console.log("Democrat Voter Data is empty or undefined.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTableData(false);
    }
  };
  const handleAllVoterLink = async () => {
    try {
      setLoadingTableData(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/search/allvoters`,
        {
          filters: {
            Street_Name: data?.Street_Name,
            House_Number: data?.House_Number,
            Precinct_Name: data?.Precinct_Name,
          },
        }
      );

      if (res.data && res.data.data) {
        setDemocratVoters(res.data.data);
        setTotalCount(res.data.data.length);
        setShowDemocratDataTable(true);
      } else {
        console.log("Democrat Voter Data is empty or undefined.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTableData(false);
    }
  };

  const handleStreetLink = async () => {
    try {
      setLoadingTableData(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/search/street`,
        {
          filters: {
            Street_Name: data?.Street_Name,
            Precinct_Name: data?.Precinct_Name,
          },
        }
      );

      if (res.data && res.data.data) {
        setDemocratVoters(res.data.data);
        setShowDemocratDataTable(true);
        tableRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        console.log("Democrat Voter Data is empty or undefined.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTableData(false);
    }
  };
  const handleCongressLink = async () => {
    try {
      setLoadingTableData(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/search/congress`,
        {
          filters: {
            County: data?.County,
            "U.S._Congress": data?.["U.S._Congress"],
          },
        }
      );

      if (res.data && res.data.data) {
        setDemocratVoters(res.data.data);
        setShowDemocratDataTable(true);
        tableRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        console.log("Democrat Voter Data is empty or undefined.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTableData(false);
    }
  };

  const handleStateHouseLink = async () => {
    try {
      setLoadingTableData(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/search/statehouse`,
        {
          filters: {
            County: data?.County,
            State_House: data?.State_House,
          },
        }
      );

      if (res.data && res.data.data) {
        setDemocratVoters(res.data.data);
        setShowDemocratDataTable(true);
        tableRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        console.log("Democrat Voter Data is empty or undefined.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTableData(false);
    }
  };
  const handleStateDistrictLink = async () => {
    try {
      setLoadingTableData(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/search/statedistrict`,
        {
          filters: {
            School_District: data?.School_District,
            County: data?.County,
          },
        }
      );

      if (res.data && res.data.data) {
        setDemocratVoters(res.data.data);
        setShowDemocratDataTable(true);
        tableRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        console.log("Democrat Voter Data is empty or undefined.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTableData(false);
    }
  };

  const handlePrecinctNameLink = async () => {
    try {
      setLoadingTableData(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/search/precinctname`,
        {
          filters: {
            Precinct_Name: data?.Precinct_Name,
            County: data?.County,
          },
        }
      );

      if (res.data && res.data.data) {
        setDemocratVoters(res.data.data);
        setShowDemocratDataTable(true);
        tableRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        console.log("Democrat Voter Data is empty or undefined.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTableData(false);
    }
  };

  const handleStateSenateLink = async () => {
    try {
      setLoadingTableData(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/search/statesenate`,
        {
          filters: {
            County: data?.County,
            State_Senate: data?.State_Senate,
          },
        }
      );

      if (res.data && res.data.data) {
        setDemocratVoters(res.data.data);
        setShowDemocratDataTable(true);
        tableRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        console.log("Democrat Voter Data is empty or undefined.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTableData(false);
    }
  };

  const loadCount = async () => {
    if (data) {
      console.log("Data is", data);
      try {
        const [democratRes, republicanRes, allVotersRes] = await Promise.all([
          axios.post(`${process.env.REACT_APP_BACKEND_URL}/search/democrat`, {
            filters: {
              Street_Name: data?.Street_Name,
              House_Number: data?.House_Number,
              Precinct_Name: data?.Precinct_Name,
            },
          }),
          axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/search/republician`,
            {
              filters: {
                Street_Name: data?.Street_Name,
                House_Number: data?.House_Number,
                Precinct_Name: data?.Precinct_Name,
              },
            }
          ),
          axios.post(`${process.env.REACT_APP_BACKEND_URL}/search/allvoters`, {
            filters: {
              Street_Name: data?.Street_Name,
              House_Number: data?.House_Number,
              Precinct_Name: data?.Precinct_Name,
            },
          }),
        ]);

        if (democratRes.data && democratRes.data.data) {
          setDemocratCount(democratRes.data.data.length);
        } else {
          console.log("Democrat Voter Data is empty or undefined.");
        }

        if (republicanRes.data && republicanRes.data.data) {
          setRepublicanCount(republicanRes.data.data.length);
        } else {
          console.log("Republican Voter Data is empty or undefined.");
        }

        if (allVotersRes.data && allVotersRes.data.data) {
          setTotalCount(allVotersRes.data.data.length);
        } else {
          console.log("All Voters Data is empty or undefined.");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchData();
    getSupportStatus();
  }, [id]);
  const tableContainerRef = useRef(null);
  useEffect(() => {
    if (loadingTableData && tableContainerRef.current) {
      tableContainerRef.current.scrollTop =
        tableContainerRef.current.scrollHeight;
    }
    if (data) {
      loadCount();
    }
  }, [data, loadingTableData]);
  const navigate = useNavigate();

  return (
    <div>
      {data ? (
        <div className="p-4 bg-gray-100">
          <div className="py-4 px-14 flex justify-between">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/")}
            >
              Back
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSupportStatusReset}
            >
              Reset Support Status
            </Button>
          </div>
          <div className="flex justify-between items-center mb-4 px-12">
            <div className="flex items-center">
              <div className="ml-3">
                <Typography variant="h4">
                  {toTitleCase(data.First_Name)} {toTitleCase(data.Last_Name)}
                </Typography>
                <Typography variant="subtitle1" className="text-gray-600">
                  Age: {Math.floor(data.Age)} Gender: {data.Gender}
                </Typography>
                <Typography variant="subtitle1" className="text-gray-600">
                  Party: {data.Party_Code}
                </Typography>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-2 py-2">
              <div className="col-span-1">
                <Button
                  variant="contained"
                  color="primary"
                  className="w-full"
                  onClick={(event) => handleClick(event, "Support")}
                  disabled={selectedOption === "Support"}
                >
                  Support
                </Button>
              </div>
              <div className="col-span-1">
                <Button
                  variant="contained"
                  color="primary"
                  className="w-full"
                  onClick={(event) => handleClick(event, "Unsure")}
                  disabled={selectedOption === "Unsure"}
                >
                  Unsure
                </Button>
              </div>
              <div className="col-span-1">
                <Button
                  variant="contained"
                  color="primary"
                  className="w-full"
                  onClick={handleCopyToClipboardVCF}
                >
                  VCF
                </Button>
              </div>

              <div className="col-span-1">
                <Button
                  variant="contained"
                  color="primary"
                  className="w-full"
                  onClick={(event) => handleClick(event, "No Support")}
                  disabled={selectedOption === "No Support"}
                >
                  No Support
                </Button>
              </div>
              <div className="col-span-1">
                <Button
                  variant="contained"
                  color="primary"
                  className="w-full"
                  onClick={(event) => handleClick(event, "No Response")}
                  disabled={selectedOption === "No Response"}
                >
                  No Response
                </Button>
              </div>
              <div className="col-span-1">
                <Button
                  variant="contained"
                  color="primary"
                  className="w-full"
                  onClick={() => handleDownload("CSV")}
                >
                  CSV
                </Button>
              </div>
            </div>
          </div>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            message="CSV data copied to clipboard"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
          <div className="flex gap-5 p-4">
            <div className="flex-col px-12 pr-8 gap-4">
              <Card elevation={3} className="mb-4 pr-5">
                <CardContent>
                  <Typography variant="h6" className="pb-2 text-blue-500">
                    Household Voters
                  </Typography>
                  <Divider className="my-2" />

                  <Typography variant="subtitle1" className="pt-2">
                    Total Voters in Household:
                    <span
                      className="text-blue-500 underline cursor-pointer px-2"
                      onClick={handleAllVoterLink}
                    >
                      {TotalCount}
                    </span>
                  </Typography>
                  <Typography variant="subtitle1">
                    Total Democratic Voters:
                    <span
                      className="text-blue-500 underline cursor-pointer px-2"
                      onClick={handleDemocratVoterLink}
                    >
                      {DemocratCount}
                    </span>
                  </Typography>
                  <Typography variant="subtitle1">
                    Total Republican Voters:
                    <span
                      className="text-blue-500 underline cursor-pointer px-2"
                      onClick={handleRepublicianVoterLink}
                    >
                      {RepublicanCount}
                    </span>
                  </Typography>
                </CardContent>
              </Card>
              <Card elevation={3} className="mb-4">
                <CardContent>
                  <Grid container spacing={1}></Grid>
                  <Typography variant="h6" className="pt-2 text-blue-500">
                    Voters Information
                  </Typography>
                  <Divider className="pt-2" />
                  <div className="pt-2"></div>
                  <Typography variant="h7" className="pt-4">
                    <b>Voter Id</b>: {data.ID_Number}
                  </Typography>
                  <br />
                  <Typography variant="" className="pt-2">
                    <b>County</b>: {toTitleCase(data.County)}
                  </Typography>
                  <br />
                  <Typography variant="" className="pt-2">
                    <b>Tel</b>: {Math.floor(data.Home_Phone)}
                  </Typography>
                  <br />
                  <Typography variant="" className="pt-2">
                    <b>Email</b>: {data.Mail_Country}
                  </Typography>
                  <br />
                  <Typography variant="" className="pt-2">
                    <b>Address</b>: {Math.floor(data.House_Number)}
                    {data.House_Number_Suffix}
                    <span
                      className="text-blue-500 underline cursor-pointer px-2"
                      onClick={handleStreetLink}
                    >
                      {toTitleCase(data.Street_Name)}
                    </span>
                    <br /> {toTitleCase(data.City)}, {data.State} {data.Zip}
                  </Typography>
                  <br />
                  <Typography variant="" className="pt-2">
                    <b>Precinct: </b>
                    <span
                      className="text-blue-500 underline cursor-pointer px-2"
                      onClick={handlePrecinctNameLink}
                    >
                      {toTitleCase(data.Precinct_Name)}
                    </span>
                    <br />
                    <b>State House: </b>
                    <span
                      className="text-blue-500 underline cursor-pointer px-2"
                      onClick={handleStateHouseLink}
                    >
                      {toTitleCase(data.State_House)}
                    </span>
                    <br />
                    <b>State Senate: </b>
                    <span
                      className="text-blue-500 underline cursor-pointer px-2"
                      onClick={handleStateSenateLink}
                    >
                      {toTitleCase(data.State_Senate)}
                    </span>

                    <br />
                    <b>Congressional: </b>
                    <span
                      className="text-blue-500 underline cursor-pointer px-2"
                      onClick={handleCongressLink}
                    >
                      {toTitleCase(data[`U.S._Congress`])}
                    </span>
                    <br />
                    <b>School District: </b>
                    <span
                      className="text-blue-500 underline cursor-pointer px-2"
                      onClick={handleStateDistrictLink}
                    >
                      {toTitleCase(data.School_District)}
                    </span>
                  </Typography>
                </CardContent>
              </Card>
            </div>

            <div className="flex-1 mr-12">
              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6" className="py-3 text-blue-500">
                    Voters
                  </Typography>
                  <Divider className="my-3" />

                  <TableContainer style={{ maxHeight: "26rem" }} className="">
                    <ElectionDataTable data={data} />
                  </TableContainer>
                </CardContent>
              </Card>
            </div>
          </div>
          {showDemocratDataTable ? (
            <div className="bg-gray-100 px-8">
              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6" className="py-3 text-blue-500">
                    Voters
                  </Typography>
                  <Divider className="my-3" />

                  <TableContainer
                    style={{ maxHeight: "26rem" }}
                    className="overflow-y-auto"
                  >
                    <DataTable data={democratVoters} />
                  </TableContainer>
                </CardContent>
              </Card>
            </div>
          ) : null}
        </div>
      ) : (
        <>Loading</>
      )}
      {loadingTableData && (
        <div className="px-16 text-xl">Loading Voter data...</div>
      )}
    </div>
  );
};

export default Profile;
