const PartyData = [
  { party: "Democrat" },
  { party: "Independent" },
  { party: "Green Party" },
  { party: "Libertarian" },
  { party: "Republican" },
  { party: "other" },
];

export default PartyData;
